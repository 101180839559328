import React from "react";
import "./Navbar.css";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

const Navbar = () => {

  const isadmin = useSelector( (xdata) => { 
    return xdata.isAdmin;
  })


  return (
    <>
      <div class="navbar">
        <div class="navbar-item hide">
            <li class="active"><a  href="#"><FaHome/>&nbsp;  Home</a></li>
            <Link to = "/Signup" className="linkcom">
            <li><a href="#">Find GSTIN</a></li>
            </Link>

            <Link to = "/importexcel" className="linkcom">
            <li><a href="#">Import Excel</a></li>
            </Link>

            <Link to = "/installproductmaster" className="linkcom">
            <li><a href="#">Product Master</a></li>
            </Link>

            { isadmin && 
                <Link to = "/flashnews" className="linkcom">
                    <li><a href="#">Flash News</a></li>
                </Link>
            }

            { isadmin && 
                <Link to = "/uploadpdf" className="linkcom">
                    <li><a href="#">PDF upload</a></li>
                </Link>
            }
         </div>
      </div>
    </>
  );
};

export default Navbar;
