import { createSlice, configureStore } from '@reduxjs/toolkit'
import { persistReducer,persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const mySlice = createSlice( { 
    name:"myslice",
    initialState: { islogin:false,isAdmin:false,username:"",flashnews2:false},


    reducers:{ 
        mylogin(store,action) {
            store.islogin = true;
        } ,
        myhome(store,action) {
            store.islogin = false;
            store.username="";
            store.isAdmin=false;
        },
        myuser(store,action) {
            store.username=action.payload;
        },
        myisAdmin(store,action){
            store.isAdmin=action.payload
            if (action.payload) {
                store.islogin=true;
            }
        },
        flashnewscloser1(store,action){
            store.flashnews2=true
        },
        setflash(store,action){
            store.flashnews2=action.payload
        }
    }
})

const persistConfig = {
    key:"root",
    storage,
    white : [
        "islogin",
        "isAdmin",
        "username"
    ]
}

const mypersistReducer=persistReducer(persistConfig,mySlice.reducer);


export let myaction = mySlice.actions 

let dataStore = configureStore ( {
    reducer:mypersistReducer
});

const persistor=persistStore(dataStore)

export { dataStore,persistor }
