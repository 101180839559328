//npm install @material-ui/core --force
//npm install @material-ui/icons

//npm install @mui/material @emotion/react @emotion/styled

//https://v4.mui.com/components/tables/

import React, { useState } from "react";
import "./importexcel.css"
import * as XLSX from "xlsx";
import { IoIosArrowDropdown } from "react-icons/io";


import TableBody from "@material-ui/core/TableBody";
import { Table, TablePagination,TableFooter } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import * as utils from "../../Utils";

import { makeStyles } from '@material-ui/core/styles';

// npm install exceljs
// const Excel = require("exceljs");

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '90%',
    minHeight: '80%'
  },
  tableContainer: {
      borderRadius: 15,
      margin: '2% 2%',
      maxWidth: '100%'
  },
  tableHeaderCell: {
      fontWeight: 'bold'
      
  },
  name: {
      fontWeight: 'bold'
      
  },
}));

const ImportExcel = () => {
  const [items, setItems] = useState([]);
  const [file,setfile]=useState(null);
  const [excelmethod,setexcelmethod]=useState(false);

  var startrow = 0;
  let mydata = [];

   const handleclick1 = ()=> {
    console.log(file)
    if (!file) {
      alert("Please Select file")
    }
    else {
      readExcel(file);
    }
  }

  const handleclick2 = ()=> {
    console.log(file)
    if (!file) {
      alert("Please Select file")
    }
    else {
      readExcel_exceljs(file);
    }
  }

  const readExcel_exceljs = async (file) => {

      const Excel = require("exceljs");

      startrow = 5;
      mydata=[];

      const wb = new Excel.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        const buffer = reader.result;

        wb.xlsx.load(buffer).then((workbook) => {
          const wss = workbook.worksheets[1];

          for (let row = startrow; row <= wss.rowCount; row++) {
            let data = [];
            data.push(wss.getCell(row, 1).value);
            data.push(wss.getCell(row, 2).value);
            data.push(wss.getCell(row, 3).value);
            data.push(wss.getCell(row, 4).value);
            data.push(wss.getCell(row, 5).value);
            data.push(wss.getCell(row, 6).value);
            data.push(wss.getCell(row, 7).value);
            data.push(wss.getCell(row, 9).value);
            data.push(wss.getCell(row, 11).value);
            data.push(wss.getCell(row, 12).value);
            data.push(wss.getCell(row, 13).value);
            data.push(wss.getCell(row, 14).value);
            data.push(wss.getCell(row, 15).value);
            data.push(wss.getCell(row, 16).value);
            mydata.push(data);
          }
          setItems(mydata);
        });
      };
  };


  const readExcel = async(file) => {
    
    

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[1];
        
        const ws = wb.Sheets[wsname];

        const range = XLSX.utils.decode_range(ws['!ref']);
        
        startrow = 4;
        mydata=[];
        
        for(let row=startrow;row<=range.e.r;row++) {
            let data=[]
            
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:0})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:1})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:2})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:3})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:4})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:5})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:6})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:8})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:10})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:11})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:12})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:13})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:14})]).v );
            data.push( (ws[XLSX.utils.encode_cell({r:row,c:15})]).v );

            mydata.push(data)
           
        }
        setItems(mydata)
    };
  };

  const handleclickenablemethod=()=>{
    setexcelmethod(!excelmethod)
    setTimeout(() => {
    setexcelmethod(false)
      
    },2000);
  }

  const ccs = { width: 12, backgroundColor: "yellow" };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();

  return (
    <>
      <div className="importexcel">
          <div className="importexcelpanel">
              <input
                type="file"
                accept=".xls, .xlsx"
                onChange={(e) => {
                    setfile(e.target.files[0])
                }}
              />

              <div className="importexcelbtn">
                <span>
                  <button onClick={handleclick1}>Download 
                  </button><IoIosArrowDropdown className="importexceldropdown" onClick={handleclickenablemethod}/>
                </span>

                { excelmethod && 
                    <div className="excelmethod">
                        <button onClick={handleclick1}>Method 1
                        </button>
          
                        <button onClick={handleclick2}>Method 2
                        </button>
                    </div>
                }
              </div>
          </div>
            
          

        
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Srl.</TableCell>
                  <TableCell style={ccs}>GSTIN</TableCell>
                  <TableCell>Receiver Name</TableCell>
                  <TableCell>Inv.No.</TableCell>
                  <TableCell>Inv.Date</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell>POS</TableCell>
                  <TableCell>Rev.</TableCell>
                  <TableCell>Inv.Type</TableCell>
                  <TableCell align="right">Rate</TableCell>
                  <TableCell align="right">Taxable Value</TableCell>
                  <TableCell align="right">IGST</TableCell>
                  <TableCell align="right">CGST</TableCell>
                  <TableCell align="right">SGST</TableCell>
                  <TableCell align="right">CESS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell>{item[0]}</TableCell>
                    <TableCell>{item[1]}</TableCell>
                    <TableCell>{item[2]}</TableCell>
                    <TableCell>{item[3]}</TableCell>
                    <TableCell>{item[4]}</TableCell>
                    <TableCell>{item[5]}</TableCell>
                    <TableCell>{item[6]}</TableCell>
                    <TableCell>{item[7]}</TableCell>
                    <TableCell>{item[8]}</TableCell>
                    <TableCell>{item[9]}</TableCell>
                    <TableCell>{item[10]}</TableCell>
                    <TableCell>{item[11]}</TableCell>
                    <TableCell>{item[12]}</TableCell>
                    <TableCell>{item[13]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>


              
            </Table>


            <TableFooter>
        <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </TableFooter>




          </TableContainer>

         
          
        </div>
    </>
  );
};

export default ImportExcel;
