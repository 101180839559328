
import "../login/login.css";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useState,useRef,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios"

const Signin = () => {

  const inputRef = useRef(null);
  
  useEffect(() => {
      inputRef.current.focus();
  }, []);

  const [password, setpassword] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [getinputdata, setgetinputdata] = useState({uname:"",email:"", password:"",confirm:"",})
  const [inputerror, setinputerror] = useState({uname:false,email:false, password:false,confirm:false,})
  
  const usernameRegex = /^[0-9A-Za-z]{6,16}$/;
  const passwordRegex = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!@#$%*_.]).{6,16}$/;
  const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function passwordhandler() {
      setpassword(!password);
  }
  
  function confirmpasswordhandler() {
      setconfirm(!confirm);
  }

  function getformdata(event) {
    let inputvalue=event.target.value
    let inputname=event.target.name
    setgetinputdata({...getinputdata,[inputname]:inputvalue})
    setinputerror({...inputerror,[inputname]:false})
  }
  
  const varNavigate=useNavigate()

  const checkUserExists = async () => {
  
    let username=getinputdata.uname

    if (username!=="") {
        const xusername=getinputdata.uname.toLowerCase()
        if (xusername==="admin") {
            alert("invalid user name")
            return;
        }

        const user = { username : username };
        const res=await axios.post("https://api.sivakasirainbow.in/readbyusername",user)
        if (res.data.length>0) {
            alert("UserName already registered...")
            return;
        }
    }

    if ((getinputdata.uname==="") || !(usernameRegex.test(getinputdata.uname))) {
        setinputerror((getmydata)=>({...getmydata,uname:true}))
    }
    else if ((getinputdata.email==="")  || !(emailRegex.test(getinputdata.email))) {
        setinputerror((getmydata)=>({...getmydata,email:true}))
        setinputerror((getmydata)=>({...getmydata,uname:false}))
    }
    else if ((getinputdata.password==="")  || !(passwordRegex.test(getinputdata.password))) {
        setinputerror((getmydata)=>({...getmydata,password:true}))
        setinputerror((getmydata)=>({...getmydata,email:false}))
    }
    else if(getinputdata.confirm==="" || getinputdata.confirm!==getinputdata.password) {
        setinputerror((getmydata)=>({...getmydata,confirm:true}))
        setinputerror((getmydata)=>({...getmydata,password:false}))
    }
    else  {
        runAPI()
    }
  };
  
  function formloginhandler() {
    varNavigate( "/login")    
  }
  
  async function runAPI() {
   
      try {
        const logindata = {
            username : getinputdata.uname,
            email:getinputdata.email,
            password:getinputdata.password
        }
        await axios.post("https://api.sivakasirainbow.in/create",logindata)
        .then((res) => {
            alert("Successfully Register");
            varNavigate( "/login")
        })
        .catch((error) => {
            console.log(error)
        });
      }
      catch{

      }
  }
  

  return (
    <>
      <div className="Container">
        <div className="ContainerInner">
          <div className="innerrightsignin">

            <div className="headicon">
              <h3><span><SiGnuprivacyguard className="signupicon" /></span></h3>
            </div>

            <div className="inputfileds">

              <div className="inputs">
                <h4>Username<span classname="mandatory">*</span></h4>
                <p>
                  <span><FaUser className="inputicon" /></span>
                  <input ref={ inputRef } type="text" name="uname" onChange={ getformdata } />
                </p>
                { inputerror.uname && <h5>Enter Username (having Minimum 6 Chars. and Maximum of 16 Chars.)</h5> }
              </div>

              <div className="inputs">
                <h4>Email<span classname="mandatory">*</span></h4>
                <p>
                  <span><MdEmail className="inputicon" /></span>
                  <input type="text"  name="email" onChange={ getformdata }/>
                </p>
                { inputerror.email && <h5>Enter Email Address</h5> }
              </div>

              <div className="inputs">
                <h4>Password<span classname="mandatory">*</span></h4>
                <p>
                  <span><RiLockPasswordLine className="inputicon" /></span>
                  <input type={ password ? "text" : "password" }  name="password" onChange={ getformdata }/>
                  { password ? (
                    <FaRegEye onClick={passwordhandler} className="eye" />
                  ) : (
                    <FaRegEyeSlash onClick={passwordhandler} className="eye" />
                  )}
                </p>
                { inputerror.password && <h5>Enter Password (having Minimum 6 Chars. and Maximum of 16 Chars., Must have one upperCase,one lowerCase,one Number and one Special Symbol [!@#$%*_.]) </h5> }
              </div>

              <div className="inputs">
                <h4>Confirm Password<span>*</span></h4>
                <p>
                  <span><RiLockPasswordLine className="inputicon" /></span>
                  <input type={ confirm ? "text" : "password" }  name="confirm" onChange={ getformdata }/>
                  { confirm ? (
                    <FaRegEye onClick={ confirmpasswordhandler } className="eye" />
                  ) : (
                    <FaRegEyeSlash onClick={ confirmpasswordhandler } className="eye" />
                  )}
                </p>
                { inputerror.confirm && <h5>Enter Confirm password</h5> }
              </div>

            </div>

            <div className="btn">
              <button onClick={formloginhandler}>
                <span>Login</span>
              </button>
              <button onClick={()=>checkUserExists()}>
                <span>Register</span>
              </button>
            </div>

          </div>

        </div>

      </div>

    </>
  );
};

export default Signin;