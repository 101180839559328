import React from "react";
import Login from "../components/login/Login"

const MyLoginPage = () => {
  return (
    <>
    <Login />
    </>
  );
};

export default MyLoginPage;