import './App.css';
import Layout from './layout/Layout';


window.CopyRightCode = "\u00a9";


function App() {
  
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
