import React from 'react'
import { useState, useEffect } from 'react'; 
import axios from 'axios';
import { useDisableNumberInputScroll } from '../../components/useDisableNumberInputScroll';

import './ProductMaster.css'

const ProductMaster = () => {

    const [uofmdata,setuofmdata]=useState([]);
    const [selecteduofm,setselecteduofm]=useState('')

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const response = await axios.get("https://api.sivakasirainbow.in/readuofm")
                setuofmdata(response.data);
            } catch (error) {
                console.error("Error:",error);
            }
        };
        fetchdata();

     }, []);

     const handeleuofmChanged = (event) => {
        setselecteduofm(event.target.value);
     }

    // const options = uofmdata.map(item => ( { value : item.uofm, label : item.uofm }))

    useDisableNumberInputScroll();

  return (
    <div>
      <div className="divmain">
        <div className="prodet">
            <h1>Product Master</h1>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Item Name</label>
                <input className='prodetailinp1' type="text" maxLength="75" />
            </div>
            
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">UOFM</label>
                <div className='prodetnumb'>
                  <select onChange={handeleuofmChanged}>
                    {uofmdata.map(data => (
                        <option key={data.id} value = { data.uofm }>{data.uofm}</option>
                    ))}
                  </select>
                </div>
            </div>

            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Case Content</label>
                <input className='prodetnumb' type="number" maxLength="4" min="0"  pattern="^[0-9]" />
                <input className='prodetnumb' type="text"  value= {selecteduofm} tabIndex="-1" readOnly />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Bdle Content</label>
                <input className='prodetnumb' type="number" maxLength="4" min="0" />
                <input className='prodetnumb' type="text"  value= {selecteduofm} tabIndex="-1"  readOnly />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Rate Per</label>
                <input className='prodetnumb' type="number" maxLength="4" min="0" />
                <input className='prodetnumb' type="text" value= {selecteduofm} tabIndex="-1"  readOnly />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Net Rate</label>
                <input type="checkbox" />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Weight</label>
                <input type="number" className='prodetnumb' step="0.001" />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Kgs. per</label>
                <input className='prodetnumb' type="number" step="0.0001" min="0"/>
                <input className='prodetnumb' type="text"  value= {selecteduofm} tabIndex="-1"  readOnly />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Without Stock</label>
                <input type="checkbox" />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Short Name</label>
                <input type="text" className='prodetailinp1' maxLength="20"/>
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Tariff No.</label>
                <input type="text" className='prodetailinp1' />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Serial</label>
                <input type="number" className='prodetnumb' maxLength="4"/>
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">HSN/SAC</label>
                <input type="text" className='prodetailinp2' maxLength="8" />
            </div>
            <div className="divproductdetails">
                <label className='prodetlabel' htmlFor="">Group Name</label>
                <input type="text" className='prodetailinp1' />
            </div>
            <div className="divproductdetailsbtns">
                <button className='prodetbtn'>Cancel</button>
                <button className='prodetbtn'>Save</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProductMaster
