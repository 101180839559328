import React from "react";
import "./emptypage.css";

const Emptypage = () => {
  
  
  return (
    <>
    <div className="mypage"></div>
    </>
  );
};

export default Emptypage;