import { Routes,Route } from "react-router-dom"

import React from "react";
import MyLoginPage from "../pages/MyLoginPage";
import Signin from "../components/signup/Signin";
import Login from "../components/login/Login";
import Emptypage from "../pages/Emptypage";
import ImportExcel from "../components/importexcel/ImportExcel";
import Uploadpdf from "../components/uploadPDF/Uploadpdf";
import Flashnews from "../components/flashNews/Flashnews";
import ProductMaster from "../components/productmaster/ProductMaster";

const Routers = () => {
  return (
    <>
    
    <Routes>
        <Route path="/" element={<MyLoginPage/>}/>
        <Route path="/login" element={ <Login/>}/>
        <Route path="/signin" element={ <Signin/>}/>
        <Route path="/emptypage" element={ <Emptypage/>}/>
        <Route path="/importexcel" element={  <ImportExcel/>}/>
        <Route path="/uploadpdf" element={  <Uploadpdf/>}/>
        <Route path="/flashnews" element={  <Flashnews/>}/>
        <Route path="/installproductmaster" element={ <ProductMaster/>}/>
    </Routes>
        
    </>
  );
};

export default Routers;
