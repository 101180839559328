
import React from 'react'
import "./login.css"
import { RiLockPasswordLine } from "react-icons/ri";
import { FaRegEye,FaRegEyeSlash } from "react-icons/fa";
import { useState,useRef,useEffect } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa6";
import { useSelector,useDispatch } from 'react-redux'
import { myaction }  from "../../redux/Mystore"
import axios from "axios"

const Login = (isLogin) => {

  const mydispatch = useDispatch()

  const [password, setpassword] = useState(false)

  const usernameRef = useRef(null)
  const passwordRef = useRef(null)

  const islogged = useSelector((xdata)=> {
    return xdata.islogin
  })

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  function passwordhandler() {
    setpassword(!password)
  }

  const formhandler = async (e) => {
    
    e.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    mydispatch( myaction.myisAdmin(false))

    const xusername=username.toLowerCase()
    const xpassword=password.toLowerCase()

    if (xusername==="admin" && xpassword==="password") {
        mydispatch( myaction.myisAdmin(true))
        mydispatch( myaction.myuser(username))
        alert("Successfully Login!");
        varNavigate( "/emptypage")
        return ;
    }

    if (username==="") {
        alert ("UserName Shoule be given");
        return
    }

    if (password==="") {
        alert ("Password Shoule be given");
        return
    }

    const user = { 
        username:xusername,
        password:password
    }

    try {
        const response = await axios.post("https://api.sivakasirainbow.in/readuser", user );
        if (response.data.length>0) {
            alert("Successfully Login!");
            mydispatch( myaction.mylogin() )
            mydispatch( myaction.myuser(username))
            varNavigate( "/emptypage")
            return;
        }
        else {
            alert("UserNasme Not found ...");
            return;
        }
      
    }
    catch (error) {
        alert("UserNasme Not found ...");
    }
  };

const varNavigate=useNavigate()

useEffect(() => {
    if (islogged) {
        varNavigate("/emptypage")
    }
},[]);

  function formsigninhandler() {
      varNavigate( "/signin")    
  }

  const defaultEnterRef=useRef(null)
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          defaultEnterRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

    
  return (
    <>
    <div className='Container'>
        <div className='ContainerInner'>
          <div className='innerright'>
            <div className='headicon'>
              <h3>
                <span><FaUser className='signupicon'/></span>
              </h3>
            </div>

            <div className='inputfileds'>
              <div className='inputs'>
                <h4>User Name</h4>
                <p>
                  <span><FaUser className='inputicon'/></span>
                  <input ref={ usernameRef } type="text"  name="username" />
                </p>
              </div>

              <div className='inputs'>
                <h4>Password</h4>
                <p>
                  <span><RiLockPasswordLine className='inputicon'/></span>
                  <input type={password ? "text":"password"}   ref={ passwordRef } name="password" />
                  {password ? <FaRegEye onClick={ passwordhandler } className='eye'/>:<FaRegEyeSlash onClick={ passwordhandler } className='eye'/>}
                </p>
              </div>
            </div>

            <div className='btn'>
              <button onClick={ formsigninhandler }><span>Register</span></button>
              <button onClick={ formhandler } ref= { defaultEnterRef }><span>Login</span></button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;